<template>
  <div class="d-flex flex-column align-center">
    <div class="flip-container">
      <div class="flipper">
          <div class="front">
            <v-img
              max-height="150"
              max-width="150"
              src="../../assets/AgentOS-Logo.png"
            ></v-img>
          </div>
          <div class="back">
            <v-img
              max-height="150"
              max-width="150"
              src="../../assets/AgentOS-Logo.png"
            ></v-img>
          </div>
          <div class="clear"></div>
      </div>
    </div>
    <div class="text-center">
      <p class="mt-5 mb-1 text-h5">Syncing your account.</p>
      <p class="my-0 font-weight-light text-body-1">This will only take a few seconds...</p>
      <p class="mt-0 font-weight-light text-caption">Try refreshing if it takes too long!</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'companyLoader'
}
</script>

<style scoped>
.flip-container {
	-webkit-perspective: 1000;
	width: 200px;
}
.flipper {
	transition: 0.6s;
	-webkit-transform-style: preserve-3d;
	position: relative;
	height: 200px;
}
.front,
.back {
	width: 200px;
	height: 200px;
  border-radius: 50%;
	position: absolute;
	left: 0;
	top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
	-webkit-backface-visibility: hidden;
  box-shadow: 5px 5px 5px #888888;
  border: 1px solid #888888;
	background-image: url('../../assets/AgentOS-Logo.png');
	text-align: center;
}
.back {
	-webkit-transform: rotateY(180deg);
	/* background: #3498db; */
}
.front {
	z-index: 2;
	/* background: #2ecc71; */
}
.flip-container p {
	margin: 10px 0;
	text-align: center;
}
/* Flip Animation */
@keyframes flipX{
    from {
        -webkit-transform: rotateX(180deg);
    }
    
    to {
        -webkit-transform: rotateX(-180deg);
    }
    
}
@keyframes flipY{
    from {
        -webkit-transform: rotateY(180deg);
    }
    
    to {
        -webkit-transform: rotateY(-180deg);
    }
    
}
.flip-container .flipper {
	animation: flipY 3s infinite;
}
</style>