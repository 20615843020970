<template>
  <div class="home">
    <v-container fluid>
      <!-- <p class="mt-1 text-h6 font-weight-light">Hi Glyn, welcome to AgentSign!</p> -->
      <div class="d-flex flex-column justify-center align-center">
        <CompanyLoader class="mt-5" v-if="!personAccounts" />
        <v-progress-linear
          v-if="!personAccounts"
          indeterminate
          color="agentPrimary"
        ></v-progress-linear>
      </div>

      <div v-if="newUserNoAccounts" class="mx-12 text-center">
        <p class="text-h4 mt-8">Hi there, you don`t seem to have any saved accounts.</p>
        <p class="text-h4 mt-8"> Please contact your agent for more information about setting up an account.</p>
        <div class="d-flex justify-center">
          <v-img
            alt="Application logo"
            class="cursor-pointer"
            contain
            src="../assets/AgentOS-Logo.png"
            height="120"
            @click="$router.push('/')"
          />
        </div>
      </div>

      <v-row>
        <v-col cols="12" sm="7" md="8">
          <div v-if="personAccounts.length">
            <v-tabs
              v-model="tab"
              class="mb-2 rounded-sm"
              background-color="white"
              show-arrows
              active-class="tertiaryDark white--text"
            >
              <v-tabs-slider color="white"></v-tabs-slider>
              <v-tab
                v-for="(array, shortname, index) in accountsSortedByShortname" 
                :key="index"
              >
                <span 
                  class="d-inline-block text-truncate custom-transform-class text-none"
                  style="max-width: 200px;"
                >{{ returnCompanyName(shortname) }}</span>
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item
                v-for="(array, shortname, index) in accountsSortedByShortname" 
                :key="index"
              >
                <div class="d-flex justify-end pt-3 pr-8 pb-0 mb-n6">
                  <v-btn 
                    @click="hiddenAccountsDialog = true" 
                    class="mr-3"
                    color="tertiaryDark" 
                    small 
                    fab
                    outlined
                  >
                    <v-icon>mdi-eye-off-outline</v-icon>
                  </v-btn>
                  <v-btn 
                    color="tertiaryDark" 
                    small 
                    outlined
                    fab
                    @click="syncPersonAccounts(false)"
                  >
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </div>
                
                <v-row class="px-5 py-1">
                  <v-col cols="12" >
                    <div class="mx-3 my-5 pr-0 text-center secondary--text text--lighten-1">
                      <p class="mt-3 mb-0 mx-0 text-h4 font-weight-medium">{{ returnCompanyName(shortname) }}</p>
                      <p class="mt-0 mb-0 mx-0 text-h6 font-weight-light">{{ sortedAccountsArray(array).length }} active accounts</p>
                    </div>
                    <!-- <v-row> -->
                      <transition-group
                        name="group-slide-left"
                        tag="div"
                        appear
                        class="d-flex flex-wrap justify-center"
                      >
                        <div
                          class="ma-3 account-card"
                          cols="12" md="4"
                          v-for="(person, index) in sortedAccountsArray(array)" 
                          :key="person.id"
                          :style="`--i: ${index}`"
                        >
                            <!-- :class="returnAccountColor(person)" -->

                          <v-card
                            class="rounded-sm"
                            :class=" person.personType === 'Applicant Tenant' ? 'tertiaryDark' : 'secondary'"
                            @click="navigateTo(person)"
                            flat
                          >
                            <!-- <v-img id="viewing-svg" class="hidden-sm-and-down" src="../assets/viewing-undraw.svg"></v-img> -->
                            <v-icon v-if="person.personType === 'Applicant Buyer'" class="card-icon" size="70" color="white">mdi-handshake</v-icon>
                            <v-icon v-if="person.personType === 'Applicant Tenant'" class="card-icon" size="70" color="white">mdi-home-city</v-icon>
                            <v-card-text class="d-flex pt-3 mt-n2">
                              <div>
                                <p v-if="person.personType === 'Applicant Tenant'" class="white--text text-h4 mb-0 ">Rental Account</p>
                                <p v-if="person.personType === 'Applicant Buyer'" class=" white--text text-h4 mb-0 ">Buyer Account</p>
                                <p class="white--text text-caption font-weight-thin mt-1 mb-10">Created: {{ person.accountCreated | formatDate }}</p>
                              </div>
                              <v-spacer></v-spacer>
                              <v-btn 
                                @click.stop="openAccountDetails(person)" 
                                color="white" 
                                x-small
                                fab
                                outlined
                              >
                                <v-icon>mdi-information-variant</v-icon>
                              </v-btn>
                            </v-card-text>
                          </v-card>
                        </div>
                      </transition-group>
                    <!-- </v-row> -->
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </v-col>
        <v-col cols="12" sm="5" md="4">
          <v-card
            v-if="personAccounts.length" 
            flat 
            class="white"
          >
            <v-card-title class="py-2 agentPrimary white--text">
              <span class="">Notifications</span>
              <v-spacer></v-spacer>
              <v-icon color="white">mdi-bell-outline</v-icon>
            </v-card-title>
            <v-card-text class="py-3">
              <v-timeline
                dense
              >
                <v-timeline-item
                  small
                  fill-dot
                  color="agentPrimary"
                >
                  <v-card
                    flat
                    outlined
                  >
                    <v-card-title class="py-2 agentSecondary darken-2 white--text">
                      <p class="mb-0 text-h5 font-weight-light">Payment due</p>
                      <v-spacer></v-spacer>
                      <v-icon color="white">mdi-alert</v-icon>
                    </v-card-title>
                    <v-card-text class="pt-3">
                      <p class="mb-0 text-h5">£350,00</p>
                      <p class="mb-0 text-body-1">10 Downing St.</p>
                      <p class="mb-0">Letmc Letting Agency</p>
                      <v-btn 
                        to="/payments"
                        color="agentPrimary" 
                        dark 
                        block 
                        depressed 
                        class="mt-5"
                      >Go to payments</v-btn>
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Test bottomg sheet for notifications -->
      <v-bottom-sheet 
        v-model="bottomSheet"
        class="d-md-none"
        inset
      >
        <v-sheet
          class="text-center"
        >
          <v-btn
            class="mt-6"
            text
            color="red"
            @click="bottomSheet = !bottomSheet"
          >
            close
          </v-btn>
          <div class="py-3">
            <v-card
              flat 
              class="white"
            >
              <v-card-text class="py-3">
                <v-card
                  class="grey lighten-2 mb-3"
                  flat
                >
                  <v-card-title class="py-2 grey darken-2 white--text">
                    <p class="mb-0 text-h5 font-weight-light">Payment due for £500 rent!</p>
                    <v-spacer></v-spacer>
                    <!-- <v-icon color="white" large>mdi-alert</v-icon> -->
                    <v-btn 
                      to="/payments"
                      color="grey darken-4" 
                      dark 
                      depressed 
                    >View now!</v-btn>
                  </v-card-title>
                </v-card>
              </v-card-text>
            </v-card>
          </div>
        </v-sheet>
      </v-bottom-sheet>

      <v-dialog
        v-model="detailsDialog"
        max-width="700px"
        transition="dialog-transition"
      >
        <v-card>
          <v-card-title class="py-2 primary white--text">
            <span class="font-weight-light text--white">Account details</span>
            <v-spacer></v-spacer>
            <v-btn small text fab color="white" @click="detailsDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pt-3">
            <div class="name">
              <p class="my-0 font-weight-bold text-h5">{{ selectedAccountDetails.personName}}</p>
              <p class="my-0 text-body-1">{{ selectedAccountDetails.displayInformation }}</p>
            </div>
            <v-divider class="my-3"></v-divider>
              <p class="my-0"><span class="font-weight-bold">Agency:</span> {{ selectedAccountDetails.companyName }}</p>
              <p class="my-0"><span class="font-weight-bold">Accountn type:</span>: {{ selectedAccountDetails.personType }}</p>
              <p class="my-0"><span class="font-weight-bold">Created: </span> {{ selectedAccountDetails.accountCreated | formatDate }}</p>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="hideAccount(selectedAccountDetails.personOID)" color="primary" depressed>
              Hide account
              <v-icon right>mdi-eye-off-outline</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="hiddenAccountsDialog"
        max-width="400px"
        transition="dialog-transition"
      >
        <v-card>
          <v-card-title class="primary py-2">
            <span class="font-weight-light white--text">Hidden accounts</span>
            <v-spacer></v-spacer>
            <v-btn small text fab color="white" @click="hiddenAccountsDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pt-3">
            <v-card 
              v-for="account in hiddenAccountsFullDetails" 
              :key="account.personOID"
              outlined
              flat
              class="
                d-md-flex 
                flex-wrap 
                justify-space-between 
                align-start 
                my-3
              "
            >
            <v-card-title class="d-block">
              <div>
                <p v-if="account.personType === 'Applicant Tenant'" class="text-h6 my-0 font-weight-medium">Rental Account</p>
                <p v-else-if="account.personType === 'Applicant Buyer'" class=" text-h6 my-0 font-weight-medium">Buyer Account</p>
                <p v-else class="text-h6 my-0 font-weight-medium">{{ account.personType }}</p>
              </div>
              <p class="my-0 text-caption">{{ account.companyName }}</p>
            </v-card-title>
            <v-card-text>
              <p class="my-0">Created: {{ account.accountCreated | formatDate }}</p>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="showAccount(account.personOID)" color="primary" depressed>
                <v-icon left>mdi-eye-outline</v-icon>
                Show account
              </v-btn>
            </v-card-actions>
            </v-card>
            <span v-if="!hiddenAccountsFullDetails.length" class="text-body-1">No hidden accounts</span>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import moment from 'moment/src/moment'
import { API, graphqlOperation  } from 'aws-amplify'
import { createPersonAccount } from '../graphql/mutations'
import { deletePersonAccount } from '../graphql/mutations'
import { listPersonAccounts } from '../graphql/queries'
import { listPersonAccountSyncDateTimes } from '../graphql/queries'
import { createPersonAccountSyncDateTime } from '../graphql/mutations'
import { updatePersonAccountSyncDateTime } from '../graphql/mutations'
import { listHiddenPersonAccountAgentSigns } from '../graphql/queries'
import { createHiddenPersonAccountAgentSign } from '../graphql/mutations'
import { deleteHiddenPersonAccountAgentSign } from '../graphql/mutations'

// import axios from 'axios'
import CompanyLoader from '@/components/reusableComponents/CompanyLoader.vue';

export default {
  name: 'homepage',
  components: {
    CompanyLoader
  },
  created() {
    if(this.$route.query.payments === 'true') { // check for immediate reroute to payments page e.g. agentsign.co.uk?payments=true
      this.$router.push({ name: 'Payments'})
    } else {
        this.checkIfAccountSyncNeeded(),
        this.getHiddenAccounts()
    }
  },
  data() {
    return {
      personAccounts: '',
      hiddenAccounts: '',
      hiddenAccountsDialog: false,
      tab: null,
      detailsDialog: false,
      selectedAccountDetails: '',
      newUserNoAccounts: false,
      // test
      bottomSheet: true
    }
  },
  methods: {
    getHiddenAccounts() {
      API.graphql(graphqlOperation(listHiddenPersonAccountAgentSigns))
        .then( res => {
          this.hiddenAccounts = res.data.listHiddenPersonAccountAgentSigns.items
        })
    },
    hideAccount(personOID) {
      const payload = {
        personOID: personOID
      }
      API.graphql(graphqlOperation(createHiddenPersonAccountAgentSign, {input: payload}))
        .then(() => {
          this.detailsDialog = false
          this.getHiddenAccounts()
        })
    },
    showAccount(personOID) { //unhide account
      //Get amplify ID for this account
      const amplifyObject = this.hiddenAccounts.find(account => account.personOID === personOID)
      const payload = {
        id: amplifyObject.id
      }
      //delete amplify obj
      API.graphql(graphqlOperation(deleteHiddenPersonAccountAgentSign, {input: payload}))
        .then( () => {
          this.getHiddenAccounts()
          this.hiddenAccountsDialog = false
        })
    },
    checkIfAccountSyncNeeded() { // Have 7 days have passed OR are URL query present
      console.log('Checking if account sync needed...')

      API.graphql(graphqlOperation(listPersonAccountSyncDateTimes)) // call DB to check if any DateTime stored for last sync...
       .then( res => {
         if(res.data.listPersonAccountSyncDateTimes.items.length) { // dateTime exists = NOT first Login
           
           const lastestSyncDateTime = Date.parse(res.data.listPersonAccountSyncDateTimes.items[0].lastUpdate)
           const dateTimeNow = Date.now()
           const oneWeek = 1000 * 60 * 60 * 24 * 7
           let timeElapsed =  dateTimeNow - lastestSyncDateTime

           if(timeElapsed > oneWeek || this.$route.query.agency) { // a week has passed OR query in URL
             this.syncPersonAccounts(false) // firstTime login false
           } else  { // a week has not passed yet && NO url query
             console.log('No sync needed, fetching saved accounts.')
             this.getSavedAccounts()
           }
         } 
         else { // no dateTime saved - First login
           console.log('First time login!')
           this.syncPersonAccounts(true) // firstTime login true
         }
       }) 
    },
    syncPersonAccounts(firstTimeLogin) { // request personAccounts (via Lambda) to AgentOS endpoint
      console.log('Requesting accounts sync from AgentOS...')

      this.personAccounts = ''
      API.get('agentSignMainRest', '/people')
        .then( res => {
          if(res.status === '200') { // response from lambda ok
            if(res.people.Data.length) { // user has personAccounts with AgentOS
              this.saveAccountSynchDateTime() // save timeDate of this account sync

              const apiReturnedAccountsArray = res.people.Data
              
              if(firstTimeLogin) { // save synced accounts to Dynamo DB
                let requests = apiReturnedAccountsArray.map(person => this.savePersonAccount(person)) 
                Promise.all(requests)
                  .then( () => {
                    this.getSavedAccounts() // accounts are saved in DB, now call Dynamo DB to render data
                  })
              } else { // not firstTime login
                this.updateSavedAccount(apiReturnedAccountsArray)
              }
            } else { // user has no personAccounts with AgentOS
              this.personAccounts = []
              this.newUserNoAccounts = true // User informend about lack of accounts with rendered msg 
            }
          } else {
            console.log('Error: ', res)
          }
        })
    },
    updateSavedAccount(latestAccounts) { // This method might very well be buggy...
      API.graphql(graphqlOperation(listPersonAccounts, {limit: 100})) // get person accounts array already saved in DB
        .then( res => {
          const savedAccounts = res.data.listPersonAccounts.items // existing accounts in DB

          // Remove accounts that are no longer relevant
          savedAccounts.forEach(savedAccount => { // compare each account by id
            let accountActive = latestAccounts.some(latestAccount => savedAccount.personOID === latestAccount.PersonOID) // notice caps difference in personOID & PersonOID
            if(!accountActive) {  // savedAccount not present among latestAccounts
              const personAccount = {
                id: savedAccount.id
              }
              return API.graphql(graphqlOperation(deletePersonAccount, {input: personAccount})) // delete savedAccount
                .then(res => {
                  console.log('Following account deleted: ', res)
                })
                .catch(err => console.log('Error deleting inactive savedAccount!', err))
            } else {
              console.log('Account still active: ', savedAccount)
            }
          })

          // Then do the reverse - save new accounts that were not previoucly present in the DB
          latestAccounts.forEach(latestAccount => {
            let accountInDataBase = savedAccounts.some(savedAccount => latestAccount.PersonOID === savedAccount.personOID) // notice caps difference in personOID & PersonOID

            if(!accountInDataBase) { // save the account in the DB
              this.savePersonAccount(latestAccount)
            } else {
              console.log('Account already exists in DB!')
            }
          })
          this.getSavedAccounts() 
        })
    },
    openAccountDetails(account) {
      this.selectedAccountDetails = account
      this.detailsDialog = true 
    },
    checkUrlParams() {
      if(this.$route.query.agency) {
        console.log('URL queries detected!')
        const availableShortnames = Object.keys(this.accountsSortedByShortname)
        const shortnameIndex = availableShortnames.indexOf(this.$route.query.agency)
        this.tab = shortnameIndex
      } else {
        console.log('No URL queries detected!')
      }
    },
    getSavedAccounts() { // returns personAccounts saved in Dynamo DB
      console.log('Getting saved accounts...')
      API.graphql(graphqlOperation(listPersonAccounts, {limit: 1000}))
        .then(res => {
          if(res.data.listPersonAccounts.items.length) {
            const personAccounts = res.data.listPersonAccounts.items
            this.personAccounts = personAccounts
            this.$store.commit('SET_ALL_PERSON_ACCOUNTS', personAccounts)
            this.checkUrlParams()
            } else { // No saved accounts so call API to sync
              this.syncPersonAccounts()
            }
        })
        .catch(err => console.log(err))
    },
    savePersonAccount(person) {  // save account to amplify DB
      const personAccount = {
        companyName: person.CompanyName,
        accountCreated: `${person.CreatedDate}Z`,
        displayInformation: person.Information,
        personName: person.PersonName,
        personOID: person.PersonOID,
        companyShortname: person.CompanyShortName,
        personType: person.PersonType
      }
      return API.graphql(graphqlOperation(createPersonAccount, {input: personAccount}))
        .then( () => console.log('PersonAccount saved.'))
        .catch( (err) => console.log('Error in savePersonAccount method.', err))
    },
    saveAccountSynchDateTime() { // saving dateTime of last requested sync from AgentOS api
      const todaysDate = new Date()
      const todaysDateISO = todaysDate.toISOString()
      
      API.graphql(graphqlOperation(listPersonAccountSyncDateTimes)) // check if DB table has any entries
        .then(res => {
          if(res.data.listPersonAccountSyncDateTimes.items.length) { // if one exists, update it...
            const savedDateTime = res.data.listPersonAccountSyncDateTimes.items[0] // 1st and only object in array
            const payload = {
              id: savedDateTime.id,
              lastUpdate: todaysDateISO
            }
            API.graphql(graphqlOperation(updatePersonAccountSyncDateTime, {input: payload})) // Update object
              .then( res => {
                console.log('appSync DateTime updated successfully: ', res)
              })
              .catch(err => console.log('appSync DateTime update ERR: ', err))
          } else { // create a first dateTime object
            const payload = {
              lastUpdate: todaysDateISO
            }
            API.graphql(graphqlOperation(createPersonAccountSyncDateTime, {input: payload}))
              .then( () => {
                console.log('SyncDateTime created!')
              })
              .catch(err => console.log(err))
          }
        })
    },
    navigateTo(person) {
      this.$store.commit('RESET_PROPERTY_VALUES')
      this.setSelectedPersonAccount(person)
      if(person.personType === 'Applicant Buyer' || person.personType === 'Applicant Tenant') {
        this.$router.push({
          name: `Property`,
          params: {
            agency: `${person.companyShortname}`
          }
        })
      }
    },
    setSelectedPersonAccount(person) {
      this.$store.commit('SET_SELECTED_PERSON_ACCOUNT', person)
    },
    returnAccountColor(person) {
      // if(person.personType == 'Tenant') return 'tertiaryDark'
      if(person.personType == 'Applicant Tenant') return 'tertiaryDark'
      if(person.personType == 'Applicant Buyer') return 'secondary'
      return 'grey'
    },
    returnCompanyName(name) {
      const company = this.personAccounts.find(obj => obj.companyShortname === name)
      return company.companyName
    },
    sortedAccountsArray(array) { // sorted by created date && only Applicant Tenant + Applicant Buyer accounts + notHidden
      const sortedByDate = array.slice().sort((a,b) => a.accountCreated < b.accountCreated ? 1 : -1)
      const onlyTenantAndBuyers = sortedByDate.filter( account => {
        if(account.personType === 'Applicant Tenant' || account.personType === 'Applicant Buyer') return account
      })
      const notHiddenAccounts = onlyTenantAndBuyers.filter( account => {
        const isHidden =  this.hiddenAccounts.some( hiddenAccount => hiddenAccount.personOID === account.personOID)
        if(!isHidden) return account
      })
      return notHiddenAccounts
    }
  },
  computed: {
    accountsSortedByShortname() {
      let personAccounts = this.personAccounts
      const reducedArray = personAccounts.reduce((acc, obj) => {
        const key = obj.companyShortname
        if(!acc[key]) {
          acc[key] = []
        }
        acc[key].push(obj)
        return acc
      }, {})
      // const objectKeys = Object.keys(reducedArray)
        // 
      return reducedArray
    },
    hiddenAccountsFullDetails() {
      if(this.personAccounts) {
        const fullDetails = this.personAccounts.filter( account => {
          const isHidden = this.hiddenAccounts.some(hiddenAccount => hiddenAccount.personOID === account.personOID)
          if(isHidden) return account
        })
        return fullDetails
      } return []
    }
  },
  filters: {
    formatDate(val) {
      return moment(val).format('DD/MM/YYYY')
    }
  }
}
</script>

<style scoped>
.account-card {
  flex: 1 1 300px;
  max-width: 425px;
}
.card-icon {
  position: absolute;
  bottom: 0;
  right: 10px;
  color: white;
  opacity: .5;
}

.hidden-account {
  border-bottom: 1px solid grey;
}

.secondary-gradient {
  background: linear-gradient(90deg, #262637 20%, #ffffff);
}

.tertiaryDark-gradient {
  background: linear-gradient(90deg, #55B6B4 20%, #ffffff);
}

</style>


// Some notes

// GET requests passing a string query parameter (See link for alternative ways)
// https://docs.amplify.aws/lib/restapi/fetch/q/platform/js#get-requests-with-query-parameters

// EXAMPLES...
// API.get('agentSignMainRest', '/todos?query=https://api.mocki.io/v1/13f44462')
//   .then( res => {
  //     console.log('Todos obj: ',  res)
//   })

// const postParams = {
//   body: {
  //     name: "Tom Jones",
//     movies: ["I Love You Man", "Role Models"]
//   },
// };

// API.post('agentSignMainRest', '/post', postParams)
//   .then( res => {
  //     console.log('Post obj res: ', res)
//   })

    
